<template>
  <div>
    <v-row
      class="info-form__header d-flex align-center justify-space-between mt-8"
      :key="tableRows && tableRows.length || 0"
    >
      <v-col cols="5">
        <h2>Individual Assignment Data</h2>
      </v-col>
      <v-col cols="7">
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              filled
              label="Field Name"
              hide-details
              :items="fieldNamesList"
              v-model="fieldName"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              filled
              hide-details
              label="Field Value"
              v-model="fieldValue"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              block
              height="56px"
              color="accent"
              hide-details
              outlined
              @click="applySpecificFieldDataToAll"
              >Apply to all [Batch]</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="geo-block d-flex mt-4"
      v-for="(value, key) in dataForAssignments" 
      :key="key">
      <v-col cols="12">
        <v-row class="row">
          <v-col cols="12" class="d-flex">
            <p class="mr-3 geo-block__name">{{ key }}</p>
            <v-btn
              class="mr-3"
              height="28px"
              color="accent"
              hide-details
              outlined
              @click="applyFieldDataToGEO"
              >Apply To GEO [BATCH] </v-btn
            >
            <v-btn
              height="28px"
              color="accent"
              hide-details
              outlined
              @click="applyFieldDataToAll"
              >Apply to all [Batch]</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="geo-block__assignment"
          :class="{ 'mt-7': index > 0, 'mt-0': index === 0 }"
          v-for="(assignment, index) in value"
          :key="index">
          <v-col cols="12">
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="3">
                <v-combobox
                  filled
                  label="Assignment Title"
                  :value="assignment.assignment_title"
                  :items="assignmentTitles"
                  hide-details
                ></v-combobox>
              </v-col>
              <v-col cols="3">
                <div>
                  <v-progress-circular v-if="statusOptionsLoading" indeterminate :size="24" />
                  <v-select
                    v-else
                    class="caption status-selector"
                    :style="{
                      backgroundColor: statusOptionsList.find((el) => el.value === assignment.status)?.color,
                    }"
                    :items="statusOptionsList"
                    item-text="value"
                    hide-details
                    outlined
                    :value="assignment.status"
                    :loading="statusOptionsLoading"
                    @change="(selected) => onChange({ item, selected })"
                    @click.native.stop.prevent
                  />
                </div>
              </v-col>
              <v-col cols="3"></v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  filled
                  label="Primary"
                  hide-details
                  clearable
                  item-text="name"
                  :items="staffDatasForGeo(key, formValues.writingDueDate)"
                  :value="assignment.primary"
                  @change="
                    (v) =>
                      handleValueSelectChange(key, v, 'primary', assignment)
                  "
                  :rules="[rules.availabile('writer')]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  filled
                  label="Secondary"
                  hide-details
                  clearable
                  item-text="name"
                  :value="assignment.secondary"
                  :items="staffDatasForGeo(key, formValues.editingDueDate)"
                  @change="
                    (v) =>
                      handleValueSelectChange(key, v, 'secondary', assignment)
                  "
                  :rules="[rules.availabile('editor')]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  filled
                  label="QC"
                  hide-details
                  clearable
                  item-text="name"
                  :value="assignment.qc"
                  :items="staffDatasForGeo(key, formValues.editingDueDate)"
                  @change="
                    (v) =>
                      handleValueSelectChange(key, v, 'qc', assignment)
                  "
                  :rules="[rules.availabile('reviewer')]"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="assignment.assignment_detail"
                  label="Assignment Detail"
                  hide-details
                  filled
                  @input="
                    (v) =>
                      handleValueSelectChange(key, v, 'assignment_detail', assignment)
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="assignment.link"
                  label="Link"
                  hide-details
                  filled
                  @input="
                    (v) =>
                      handleValueSelectChange(key, v, 'link', assignment)
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="assignment.quantity"
                  label="Quantity"
                  hide-details
                  filled
                  type="number"
                  @input="
                    (v) =>
                      handleValueSelectChange(key, v, 'quantity', assignment)
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="assignment.work_type"
                  label="Work Type"
                  hide-details
                  filled
                  :items="workFlowsList"
                  @input="
                    (v) =>
                      handleValueSelectChange(key, v, 'work_type', assignment)
                  "
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { STATUS_COLORS } from "@/utils/constants";
import {
  getEnum,
  getCustomerPriceList
} from "@/utils/newDbUtils";

export default {
  name: "BooksStaffTable",
  props: {
    staffDatas: {
      required: false,
      type: Array,
    },
    tableRows: {
      required: false,
      type: Array,
    },
    relatedProject: {
      required: false,
      type: Object,
    }
  },
  data() {
    return {
      fieldNamesList: ["Assignment Detail", "Link", "Quantity", "Work Type"],
      fieldName: null,
      fieldValue: null,
      statusOptionsList: [],
      statusOptionsLoading: false,
      currentStatus: "Assigned",
      link: null,
      dataForAssignments: {},
      amAssignmentTitles: [
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Classical Playlist Copy",
        "Playlist Title",
      ],
      fcAssignmentTitles: [
        "Album Review",
        "Arcade File",
        "Artist Bio",
        "Artist Interview Edit",
        "Artist Interview Prep",
        "Artist Interview Transcript",
        "Book Review",
        "Custom Tag",
        "Custom Title",
        "Discovery File",
        "Editorial Feature",
        "Guidelines",
        "Hero Blurb",
        "Hourly Localization Work",
        "Interview Note",
        "Marketing Copy",
        "MAS File",
        "Meeting",
        "Movie Description",
        "P1 Playlist Copy",
        "P3 Playlist Copy",
        "Storefront Description",
        "Today File",
      ],
      rules: {
        availabile: (specialist) =>
          !this[`${specialist}Unavailable`] ||
          `Unavailable on selected due date for ${this.newSpecialists[specialist]}.`,
        negativeCheck: (value) => value >= 0 || "Quantity can't be nagative.",
      },
    }
  },
  computed: {
    ...mapGetters("assignment", [
      "statusOptions",
    ]),
    ...mapGetters("spreadsheetUpload", [
      "staffListData",
      "formValues",
      "adjustedCapacityPool",
      "staffAssignments",
    ]),
    ...mapGetters("projects", ["gGetCustomerPriceList"]),
    assignmentTitles() {
       return [...this.amAssignmentTitles, ...this.fcAssignmentTitles].sort();
    },
    workFlowsList() {
      return this.gGetCustomerPriceList.filter((el) => 
        this.relatedProject.workTypes.includes(el.id)).map((el) => 
          el.workTypeName);
    }
  },
  watch: {
    tableRows() {
      if (this.tableRows.length) {
        this.prepareDataForAssignments();
      }
    }
  },
  methods: {
    ...mapActions("assignment", [
      "setCommonData"
    ]),
    ...mapActions("spreadsheetUpload", [
      "setStaffAssignments",
      "setStaffAssignmentOverloading",
      "setDubEditorAssignmentsList"
    ]),
    ...mapActions("projects", ["aSetCustomerPriceList"]),
    handleValueSelectChange(key, value, field, assignment) {
      const currentAssignment = this.dataForAssignments[key].find(
          (item) => item.assignment_title === assignment.assignment_title &&
          item.assignment_detail === assignment.assignment_detail &&
          item.geo === assignment.geo &&
          item.po === assignment.po
        );
      if (value) {
        if (currentAssignment) {
          currentAssignment[field] = value;
        }
      } else {
        currentAssignment[field] = null;
      }
      console.log("this.dataForAssignments", this.dataForAssignments)
      this.setDubEditorAssignmentsList(this.dataForAssignments);
    },
    staffDatasForGeo(panelItem, date) {
      if (!this.staffDatas) return ["none"];

      const retVal = this.staffDatas
        .filter((el) => el.geos.includes(panelItem))
        .map(({ name, disabled }) => {
          return { name, disabled };
        });
      if (date)
        retVal.forEach((el) => {
          const unavailablePerson = this.adjustedCapacityPool.find(
            (item) => item.name == el.name && item.dates.includes(date)
          );
          if (unavailablePerson) {
            const workloads = [
                "adjustedEditingWorkload",
                "adjustedWritingWorkload",
              ],
              unavailabilityValidator = workloads.map((item) => {
                if (unavailablePerson[item] == 0) return true;
              });
            unavailabilityValidator.every((item) => item === true) &&
            !this.formValues.overrideStaff
              ? (el.disabled = true)
              : (el.disabled = false);
          } else {
            el.disabled = false;
          }
        });

      if (this.formValues.individualGeosDeadlines.length) {
        this.formValues.individualGeosDeadlines.forEach((el) => {
          if (el[panelItem] && el[panelItem].writingDueDate) {
            retVal.forEach((el2) => {
              const unavailablePerson = this.adjustedCapacityPool.find(
                (item) => item.name == el2.name && item.dates.includes(el[panelItem].writingDueDate)
              );
              if (unavailablePerson) {
                const workloads = [
                    "adjustedEditingWorkload",
                    "adjustedWritingWorkload",
                  ],
                  unavailabilityValidator = workloads.map((item) => {
                    if (unavailablePerson[item] == 0) return true;
                  });
                unavailabilityValidator.every((item) => item === true) &&
                !this.formValues.overrideStaff
                  ? (el2.disabled = true)
                  : (el2.disabled = false);
              } else {
                el2.disabled = false;
              }
            });
          }
          if (el[panelItem] && el[panelItem].editingDueDate) {
            retVal.forEach((el2) => {
              const unavailablePerson = this.adjustedCapacityPool.find(
                (item) => item.name == el2.name && item.dates.includes(el[panelItem].editingDueDate)
              );
              if (unavailablePerson) {
                const workloads = [
                    "adjustedEditingWorkload",
                    "adjustedWritingWorkload",
                  ],
                  unavailabilityValidator = workloads.map((item) => {
                    if (unavailablePerson[item] == 0) return true;
                  });
                unavailabilityValidator.every((item) => item === true) &&
                !this.formValues.overrideStaff
                  ? (el2.disabled = true)
                  : (el2.disabled = false);
              } else {
                el2.disabled = false;
              }
            });
          }
          if (el[panelItem] && el[panelItem].reviewingDueDate) {
            retVal.forEach((el2) => {
              const unavailablePerson = this.adjustedCapacityPool.find(
                (item) => item.name == el2.name && item.dates.includes(el[panelItem].reviewingDueDate)
              );
              if (unavailablePerson) {
                const workloads = [
                    "adjustedEditingWorkload",
                    "adjustedWritingWorkload",
                  ],
                  unavailabilityValidator = workloads.map((item) => {
                    if (unavailablePerson[item] == 0) return true;
                  });
                unavailabilityValidator.every((item) => item === true) &&
                !this.formValues.overrideStaff
                  ? (el2.disabled = true)
                  : (el2.disabled = false);
              } else {
                el2.disabled = false;
              }
            });
          }
        });
      }

      return retVal;
    },
    applyFieldDataToGEO() {
      console.log("this.tableRows", this.tableRows)
      console.log("this.staffListData", this.staffListData)
    },
    applyFieldDataToAll() {
      console.log("this.tableRows", this.tableRows)
      console.log("this.staffListData", this.staffListData)
    },
    applySpecificFieldDataToAll() {
      if (this.fieldName && this.fieldValue) {
        for (let key in this.dataForAssignments) {
          this.dataForAssignments[key].forEach((el) => {
            if (this.fieldName === "Assignment Detail") {
              el.assignment_detail = this.fieldValue;
            } else if (this.fieldName === "Link") {
              el.link = this.fieldValue;
            } else if (this.fieldName === "Quantity") {
              el.quantity = this.fieldValue;
            } else if (this.fieldName === "Work Type") {
              el.work_type = this.fieldValue;
            }
          });
        }
      }
    },
    prepareDataForAssignments() {
      const newTableRows = [...this.tableRows];
      newTableRows.forEach((el) => {
        /* ["primary", "secondary", "qc"].forEach((role) => {
          if (el[role]) {
            el[role] = this.staffListData.find((staff) => (staff.user.firstName + " " + staff.user.lastName) === el[role]);
          }
        }); */
        if (el.link && el.link.hyperlink) {
          const temporaryHeat = el.link.hyperlink;
          delete el.link;
          el.link = temporaryHeat;
        }
        if (this.dataForAssignments[el.geo]) {
          this.dataForAssignments[el.geo].push(el);
        } else {
          this.dataForAssignments[el.geo] = [el];
        }
      });
    },
    getStatuses() {
      this.statusOptionsLoading = true;
      getEnum("AssignmentStatus").then((data) => {
        this.statusOptionsList = data.map((el) => ({
          value: el,
          color: STATUS_COLORS.find((color) => color.statuses.includes(el))
            ?.value,
        }));
        this.setCommonData({ field: "statusOptions", data: data });
        this.statusOptionsLoading = false;
      });
    },
    getWorkFlowsList() {
      getCustomerPriceList().then((data) => {
        this.aSetCustomerPriceList(data);
      });
    }
  },
  mounted() {
    this.getStatuses();
    this.getWorkFlowsList();
  }
};
</script>

<style lang="scss" scoped>
.geo-block {
  background: #EFEFEF;
  border-radius: 10px;
  padding: 20px;
  &__name {
    font-family: Roboto;
    font-weight: 400;
    font-size: 22px;
  }
  &__assignment {
    box-shadow: 0px 2px 4px 0px #00000040;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 20px;
  }
}
</style>