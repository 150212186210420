<template>
  <v-card class="d-flex flex-column grey lighten-2">
    <v-form
      ref="form"
      class="ad-new-assignment v-card v-sheet theme--light ma-4"
      v-if="dubEditorData"
    >
      <v-container>
        <v-row class="mb-0 mt-2 d-flex align-center px-3">
          <v-col
              cols="3"
              class="d-flex align-center mt-0 px-0 py-0 title"
          >
            <v-icon class="">mdi-format-list-bulleted</v-icon>
            <p class="form__title ml-2 mt-0 mb-0">DUBEDITOR</p>
          </v-col>
          <v-col
              cols="3"
              class="d-flex mt-0 px-0 py-0 title"
              v-if="newAssignmentDatas[0].project?.dubEditor?.styleGuide"
          >
            <a :href="newAssignmentDatas[0].project?.dubEditor?.styleGuide" target="_blank" class="dubEditor__link">Style Guide Link</a>
          </v-col>
          <v-col
              :cols="newAssignmentDatas[0].project?.dubEditor?.styleGuide ? 6 : 9"
              class="my-0 py-0"
          >
            <v-text-field
              outlined
              dense
              label="Assignment Name"
              readonly
              hide-details
              :value="newAssignmentDatas[0].assignmentDetails.assignmentTitle"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-0 mt-7 d-flex align-center px-3">
            <v-col
                cols="12"
                class="my-0 pa-0"
            >
              <v-text-field
                outlined
                dense
                label="Assignment Details"
                readonly
                hide-details
                :value="newAssignmentDatas[0].assignmentDetails.assignmentDetail"
              ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex align-center pt-2">
            <button class="custom-button-1 d-flex align-center"
              :class="{ 'custom-button-1-bradius': newAssignmentDatas[0].secondary, 'active-button': dubEditorTab === 'Primary' }"
              @click.prevent="setDubEditorTab('Primary')">
              <v-icon 
                v-if="dubEditorData?.primaryFragments && dubEditorData?.primaryFragments.complete"
                :class="{ 'dub-icon-active': dubEditorTab === 'Primary' }"
                class="dub-icon">
                mdi-check
              </v-icon>
              <div :class="{ 'ml-2': dubEditorTab === 'Primary' }">
                <p class="primary-text-1">PRIMARY</p>
                <p class="secondary-text-1">{{ dubEditorData.primaryFragments.staffName ?? '' }}</p>
              </div>
            </button>
            <button class="custom-button-2 d-flex align-center"
              :class="{ 'custom-button-2-bradius': newAssignmentDatas[0].qc, 'active-button': dubEditorTab === 'Secondary' }"
              v-if="newAssignmentDatas[0].secondary"
              @click.prevent="setDubEditorTab('Secondary')">
              <v-icon 
                v-if="dubEditorData?.secondaryFragments && dubEditorData?.secondaryFragments.complete"
                :class="{ 'dub-icon-active': dubEditorTab === 'Secondary' }"
                class="dub-icon">
                mdi-check
              </v-icon>
              <div :class="{ 'ml-2': dubEditorTab === 'Secondary' }">
                <p class="primary-text-2">SECONDARY</p>
                <p class="secondary-text-2">{{ dubEditorData.secondaryFragments.staffName ?? '' }}</p>
              </div>
            </button>
            <button class="custom-button-3 d-flex align-center"
              :class="{ 'active-button': dubEditorTab === 'QC' }"
              v-if="newAssignmentDatas[0].qc"
              @click.prevent="setDubEditorTab('QC')">
              <v-icon
                v-if="dubEditorData?.qcFragments && dubEditorData?.qcFragments.complete"
                :class="{ 'dub-icon-active': dubEditorTab === 'QC' }"
                class="dub-icon">
                mdi-check
              </v-icon>
              <div :class="{ 'ml-2': dubEditorTab === 'QC' }">
                <p class="primary-text-2">QC</p>
                <p class="secondary-text-2">{{ dubEditorData.qcFragments.staffName ?? '' }}</p>
              </div>
            </button>
          </v-col>
        </v-row>
        <v-row v-for="(dubEditor, index) in dubEditorDataToShow?.fragments" :key="index">
          <v-col cols="12" class="d-flex align-center" :key="dubEditorTab">
            <TipTap v-if="!tipTapDestroy"
              :data="dubEditor"
              :isReadOnly="(user.user.userRole === 'STAFF' && dubEditorDataToShow.staffId !== user.user.id) || (prevIsNotReady && dubEditorTab !== 'Primary')"
              @update:content="updateContent($event)"/>
          </v-col>
        </v-row>
      </v-container>
      </v-form>
    <v-card-actions class="d-flex justify-between pr-4 pb-4"
      :class="{ 'justify-end': !changesWereAdded }">
      <v-alert
        v-if="checkIfShowResubmitAlert"
        class="mb-0 d-flex"
        icon="mdi-information-outline"
        text
        dense
        type="info"
      >
        <v-row align="center">
          <v-col class="grow">
            There are changes that were not submitted
          </v-col>
          <v-col class="shrink pa-1">
            <v-btn
              color="#1976D2"
              plain
              :loading="dubLoading"
              @click="discardChanges"
            >
              discard
            </v-btn>
          </v-col>
          <v-col class="shrink pa-1">
            <v-btn
              color="#FC7777"
              plain
              :loading="dubLoading"
              @click="confirmationDubReSubmition = true"
            >
              Resubmit
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        v-else-if="checkIfShowSaveDraftAlert"
        class="mb-0 d-flex"
        icon="mdi-information-outline"
        text
        dense
        type="info"
      >
        <v-row align="center">
          <v-col class="grow">
            There are unsaved changes
          </v-col>
          <v-col class="shrink pa-1">
            <v-btn
              color="#1976D2"
              plain
              :loading="dubLoading"
              @click="handleDubProcessing('SaveDraft')"
            >
              Save draft
            </v-btn>
          </v-col>
          <v-col class="shrink pa-1">
            <v-btn
              color="#FC7777"
              plain
              :loading="dubLoading"
              @click="discardChanges"
            >
              discard
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        v-else-if="prevIsNotReady && dubEditorTab !== 'Primary'"
        class="mb-0 d-flex"
        icon="mdi-information-outline"
        text
        dense
        type="info"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ user.user.userRole !== 'STAFF' ? "The previous person in the workflow" : dubEditorTab === "QC" ? "Secondary" : "Primary" }} has not finished the texts yet
          </v-col>
        </v-row>
      </v-alert>
      <v-btn
        v-if="!checkIfShowResubmitButton && !checkIfShowResubmitAlert"
        color="#1976D2"
        class="mx-3 white--text"
        :loading="dubLoading"
        :disabled="checkIfDisableSaveButton"
        @click="confirmationDubSubmition = true"
        >{{
            changesWereAdded ? "Save & Submit" : "Submit"
          }}</v-btn
        >
      <v-btn
        v-if="checkIfShowResubmitButton"
        color="#FF5252"
        class="mx-3 white--text"
        :loading="dubLoading"
        @click="confirmationDubUnSubmition = true"
        >unsubmit</v-btn
      >
    </v-card-actions>
    <v-dialog
      max-width="500"
      v-model="confirmationDubSubmition"
      @click:outside="confirmationDubSubmition = false"
      @keydown.esc="confirmationDubSubmition = false"
    >
      <div class="confirmation-modal">
        <p class="confirmation-modal--title" >Confirm Submission</p>
        <p class="confirmation-modal--text">Are you sure you want to submit these texts? Once submitted, the assignment status will change to <span style="color: #1976D2">'{{ dubEditorTab }} Complete'</span>, and the texts will be forwarded to the next person in the workflow for further processing.</p>
        <div class="confirmation-modal--buttons d-flex align-center justify-end">
          <v-btn
            depressed
            :loading="dubLoading"
            @click="confirmationDubSubmition = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#1976D2"
            class="mx-3 white--text"
            :loading="dubLoading"
            @click="handleDubProcessing(`Submit`)"
            >{{
              changesWereAdded ? "Save & Submit" : "Submit"
            }}</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog
      max-width="500"
      v-model="confirmationDubUnSubmition"
      @click:outside="confirmationDubUnSubmition = false"
      @keydown.esc="confirmationDubUnSubmition = false"
    >
      <div class="confirmation-modal">
        <p class="confirmation-modal--title" >Confirm Unsubmission</p>
        <p class="confirmation-modal--text">Are you sure you want to unsubmit these texts? Once confirmed, the assignment status will change to <span style="color: #1976D2">'{{ showPrevStatus }}'</span>, and the texts will no longer be accessible to the next person in the workflow until they are resubmitted.</p>
        <div class="confirmation-modal--buttons d-flex align-center justify-end">
          <v-btn
            depressed
            :loading="dubLoading"
            @click="confirmationDubUnSubmition = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#FF5252"
            class="mx-3 white--text"
            :loading="dubLoading"
            @click="handleDubProcessing(`Unsubmit`)"
            >unsubmit</v-btn
          >
        </div>
      </div>
    </v-dialog>
    <v-dialog
      max-width="500"
      v-model="confirmationDubReSubmition"
      @click:outside="confirmationDubReSubmition = false"
      @keydown.esc="confirmationDubReSubmition = false"
    >
      <div class="confirmation-modal">
        <p class="confirmation-modal--title" >Confirm Resubmission</p>
        <p class="confirmation-modal--text">Are you sure you want to resubmit these texts? Once confirmed, the assignment status will change to <span style="color: #1976D2">'{{ dubEditorTab }} Complete'</span>, and the resubmitted texts will override all previous changes for the next person in the workflow.</p>
        <div class="confirmation-modal--buttons d-flex align-center justify-end">
          <v-btn
            depressed
            :loading="dubLoading"
            @click="confirmationDubReSubmition = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#FF5252"
            class="mx-3 white--text"
            :loading="dubLoading"
            @click="handleDubProcessing(`Resubmit`)"
            >
              Resubmit
            </v-btn
          >
        </div>
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
// vuex
import { mapActions, mapGetters } from "vuex";
// libraries
import _ from "lodash";
// internal
import {
  processDubEditor,
  getAssignmentById
} from "@/utils/newDbUtils";
import TipTap from "@/components/ui/TipTap.vue";

export default {
  name: "ADDubEditorModal",
  components: { TipTap },
  data() {
    return {
      dubEditorTab: "Primary",
      tipTapDestroy: false,
      dubLoading: false,
      dubEditorData: null,
      dubEditorDataClone: null,
      confirmationDubSubmition: false,
      confirmationDubReSubmition: false,
      confirmationDubUnSubmition: false
    };
  },
  computed: {
    ...mapGetters("assignment", [
      "newAssignmentDatas",
      "dubEditorDatas"
    ]),
    showPrevStatus() {
      if (this.dubEditorTab === "Primary") {
        return "Assigned"
      } else if (this.dubEditorTab === "Secondary") {
        return "Primary Complete"
      } else if (this.dubEditorTab === "QC") {
        return "Secondary Complete"
      }
    },
    ...mapGetters("auth", ["user"]),
    dubEditorDataToShow() {
      if (this.dubEditorTab === "Primary") {
        this.dubEditorData?.primaryFragments.fragments.sort((a, b) => a.order - b.order);
        return this.dubEditorData?.primaryFragments
      } else if (this.dubEditorTab === "Secondary") {
        this.dubEditorData?.secondaryFragments.fragments.sort((a, b) => a.order - b.order);
        return this.dubEditorData?.secondaryFragments
      } else if (this.dubEditorTab === "QC") {
        this.dubEditorData?.qcFragments.fragments.sort((a, b) => a.order - b.order);
        return this.dubEditorData?.qcFragments
      }
      return this.dubEditorData
    },
    checkIfAllowed() {
      let forbidden = false;
      if (this.dubEditorTab === "Primary" && this.user.user.userRole === "STAFF" && this.user.user.id === this.dubEditorData?.primaryFragments?.staffId) {
        forbidden = true;
      } else if (this.dubEditorTab === "Secondary" && this.user.user.userRole === "STAFF" && this.user.user.id === this.dubEditorData?.secondaryFragments?.staffId) {
        forbidden = true;
      } else if (this.dubEditorTab === "QC" && this.user.user.userRole === "STAFF" && this.user.user.id === this.dubEditorData?.qcFragments?.staffId) {
        forbidden = true;
      } else if (this.user.user.userRole !== "STAFF") {
        forbidden = true
      }
      return forbidden
    },
    checkIfDisableSaveButton() {
      let show = false;
      if (this.dubEditorTab === "Primary") {
        if (this.checkIfAllowed) {
          if (this.dubEditorData?.primaryFragments?.fragments.map(item => item.text).every(item => item === "")) {
            show = true;
            return show;
          }
          if (!this.dubEditorData?.primaryFragments?.complete) {
            show = false
          }
        } else return true
      } else if (this.dubEditorTab === "Secondary") {
        if (this.checkIfAllowed) {
          if (this.dubEditorData?.secondaryFragments?.fragments.map(item => item.text).every(item => item === "")) {
            show = true;
            return show;
          }
          if (!this.dubEditorData?.secondaryFragments?.complete) {
            show = false
          }
          if (!this.dubEditorData?.primaryFragments?.complete) {
            return true;
          }
        } else return true
      } else if (this.dubEditorTab === "QC") {
        if (this.checkIfAllowed) {
          if (this.dubEditorData?.qcFragments?.fragments.map(item => item.text).every(item => item === "")) {
            show = true;
            return show;
          }
          if (!this.dubEditorData?.qcFragments?.complete) {
            show = false
          }
          if (!this.dubEditorData?.secondaryFragments?.complete) {
            return true;
          }
        } else return true
      }
      return show
    },
    checkIfShowResubmitAlert() {
      let resubmitAlert = false;
      if (this.dubEditorTab === "Primary") {
        if (this.dubEditorData?.primaryFragments?.complete && this.changesWereAdded) {
          resubmitAlert = true
        }
      } else if (this.dubEditorTab === "Secondary") {
        if (this.dubEditorData?.secondaryFragments?.complete && this.changesWereAdded) {
          resubmitAlert = true
        }
      } else if (this.dubEditorTab === "QC") {
        if (this.dubEditorData?.qcFragments?.complete && this.changesWereAdded) {
          resubmitAlert = true
        }
      }
      return resubmitAlert
    },
    checkIfShowResubmitButton() {
      let showButton = false;
      if (this.dubEditorTab === "Primary") {
        if (this.checkIfAllowed) {
          if (this.dubEditorData?.primaryFragments?.complete && !this.changesWereAdded) {
            showButton = true
          }
        }
      } else if (this.dubEditorTab === "Secondary") {
        if (this.checkIfAllowed) {
          if (this.dubEditorData?.secondaryFragments?.complete && !this.changesWereAdded) {
            showButton = true
          }
        }
      } else if (this.dubEditorTab === "QC") {
        if (this.checkIfAllowed) {
          if (this.dubEditorData?.qcFragments?.complete && !this.changesWereAdded) {
            showButton = true
          }
        }
      }
      return showButton
    },
    checkIfShowSaveDraftAlert() {
      let saveDraftAlert = false;
      if (this.dubEditorTab === "Primary") {
        if (!this.dubEditorData?.primaryFragments?.complete && this.changesWereAdded) {
          saveDraftAlert = true
        }
      } else if (this.dubEditorTab === "Secondary") {
        if (!this.dubEditorData?.secondaryFragments?.complete && this.changesWereAdded) {
          saveDraftAlert = true
        }
      } else if (this.dubEditorTab === "QC") {
        if (!this.dubEditorData?.qcFragments?.complete && this.changesWereAdded) {
          saveDraftAlert = true
        }
      }
      return saveDraftAlert
    },
    prevIsNotReady() {
      let prevIsNotReady = false;
      if (this.dubEditorTab === "Secondary") {
        prevIsNotReady = !this.dubEditorData?.primaryFragments?.complete
      } else if (this.dubEditorTab === "QC") {
        prevIsNotReady = !this.dubEditorData?.secondaryFragments?.complete
      }
      return prevIsNotReady
    },
    changesWereAdded() {
      if (this.dubEditorTab === "Primary") {
        return !_.isEqual(this.dubEditorData?.primaryFragments, this.dubEditorDataClone?.primaryFragments);
      } else if (this.dubEditorTab === "Secondary") {
        return !_.isEqual(this.dubEditorData?.secondaryFragments, this.dubEditorDataClone?.secondaryFragments);
      } else if (this.dubEditorTab === "QC") {
        return !_.isEqual(this.dubEditorData?.qcFragments, this.dubEditorDataClone?.qcFragments);
      }
    }
  },
  methods: {
    ...mapActions("assignment", [
      "setDubEditorDatas",
      "setDubEditorModalOpen"
    ]),
    ...mapActions("flashMessage", ["handleFlash"]),
    setDubEditorTab(tab) {
      this.dubEditorTab = tab;
    },
    async prepareEditorData() {
      const response = await getAssignmentById(this.newAssignmentDatas[0].id);
      if (response.dubEditorAssignment && response.project.dubEditor) {
        const dubClone = _.cloneDeep(response.dubEditorAssignment);
        if (dubClone && dubClone.primaryFragments) {
          dubClone.primaryFragments.fragments.forEach((fragment) => {
            fragment.min = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.min;
            fragment.max = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.max;
            fragment.count = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.count;
          })
        }
        if (dubClone && dubClone.secondaryFragments) {
          dubClone.secondaryFragments.fragments.forEach((fragment) => {
            fragment.min = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.min;
            fragment.max = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.max;
            fragment.count = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.count;
          })
          if (
            dubClone.primaryFragments?.fragments.length &&
            dubClone.secondaryFragments?.fragments.length &&
            dubClone.primaryFragments?.complete &&
            dubClone.secondaryFragments?.fragments.map(item => item.text).every(item => item === "")
          ) {
            dubClone.primaryFragments?.fragments.forEach((el) => {
              const fragmentItem = dubClone.secondaryFragments && dubClone.secondaryFragments?.fragments.find(item => item.fragmentId === el.fragmentId);
              if (fragmentItem) {
                fragmentItem.text = el.text;
              }
            })
          }
        }
        if (dubClone && dubClone.qcFragments) {
          dubClone.qcFragments.fragments.forEach((fragment) => {
            fragment.min = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.min;
            fragment.max = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.max;
            fragment.count = response.project.dubEditor.fragments.find(el => el.id === fragment.fragmentId)?.count;
          })
          if (
            dubClone.secondaryFragments?.fragments.length &&
            dubClone.qcFragments?.fragments.length &&
            dubClone.secondaryFragments?.complete &&
            dubClone.qcFragments?.fragments.map(item => item.text).every(item => item === "")
          ) {
            dubClone.secondaryFragments?.fragments.forEach((el) => {
              const fragmentItem = dubClone.qcFragments && dubClone.qcFragments?.fragments.find(item => item.fragmentId === el.fragmentId);
              if (fragmentItem) {
                fragmentItem.text = el.text;
              }
            })
          }
        }
        ["primaryFragments", "secondaryFragments", "qcFragments"].forEach(item => {
          dubClone[item]?.fragments?.sort((a, b) => a.order - b.order);
        })
        this.dubEditorData = dubClone;
        this.dubEditorDataClone = _.cloneDeep(this.dubEditorData);
      }
    },
    updateContent(e) {
      ["primaryFragments", "secondaryFragments", "qcFragments"].forEach(item => {
        this.dubEditorData && this.dubEditorData[item] && this.dubEditorData[item].fragments.length && this.dubEditorData[item].fragments.forEach(el => {
          if (el.id === e.data.id && e.data.fragmentId === el.fragmentId) {
            el.text = e.editorText;
          }
        })
      });
    },
    async discardChanges() {
      this.dubEditorData = null;
      await this.prepareEditorData();
    },
    async handleDubProcessing(variant) {
      this.dubLoading = true;
      if (variant === "Submit" || variant === "Resubmit") {
        if (this.dubEditorTab === "Primary") {
          this.dubEditorData.primaryFragments.complete = true;
        } else if (this.dubEditorTab === "Secondary") {
          this.dubEditorData.secondaryFragments.complete = true;
        } else if (this.dubEditorTab === "QC") {
          this.dubEditorData.qcFragments.complete = true;
        }
      }
      if (variant === "Resubmit") {
        if (this.dubEditorTab === "Primary") {
          this.dubEditorData.secondaryFragments && this.dubEditorData.secondaryFragments.fragments.length && this.dubEditorData.secondaryFragments.fragments.forEach((el) => {
            el.text = this.dubEditorData.primaryFragments.fragments.find(item => item.fragmentId === el.fragmentId)?.text;
          })
          if (this.dubEditorData.secondaryFragments) this.dubEditorData.secondaryFragments.complete = false;
          this.dubEditorData.qcFragments && this.dubEditorData.qcFragments.fragments.length && this.dubEditorData.qcFragments.fragments.forEach((el) => {
            el.text = "";
          })
          if (this.dubEditorData.qcFragments) this.dubEditorData.qcFragments.complete = false;
        } else if (this.dubEditorTab === "Secondary") {
          if (this.dubEditorData.qcFragments) this.dubEditorData.qcFragments.complete = false;
          this.dubEditorData.qcFragments && this.dubEditorData.qcFragments.fragments.length && this.dubEditorData.qcFragments.fragments.forEach((el) => {
            el.text = this.dubEditorData.secondaryFragments.fragments.find(item => item.fragmentId === el.fragmentId)?.text;
          })
        } else if (this.dubEditorTab === "QC") {
          if (this.dubEditorData.qcFragments) this.dubEditorData.qcFragments.complete = true;
        }
      }
      if (variant === "Unsubmit") {
        if (this.dubEditorTab === "Primary") {
          this.dubEditorData.primaryFragments.complete = false;
          ["secondaryFragments", "qcFragments"].forEach(item => {
            this.dubEditorData && this.dubEditorData[item] && this.dubEditorData[item].fragments.length && this.dubEditorData[item].fragments.forEach(el => {
              el.text = "";
            })
          });
        } else if (this.dubEditorTab === "Secondary") {
          this.dubEditorData.secondaryFragments.complete = false;
          this.dubEditorData && this.dubEditorData.qcFragments && this.dubEditorData.qcFragments.fragments.length && this.dubEditorData.qcFragments.fragments.forEach(el => {
            el.text = "";
          })
        } else if (this.dubEditorTab === "QC") {
          this.dubEditorData.qcFragments.complete = false;
        }
      }
      this.confirmationDubSubmition =
        this.confirmationDubReSubmition =
        this.confirmationDubUnSubmition = false;
      const response = await processDubEditor(this.dubEditorData);
      if (response.status === 200) {
        this.handleFlash({ response: {data: {message:
          variant === "Submit" ?
            "Text submitted successfully." :
            variant === "Resubmit" ?
            "Text resubmitted successfully." :
            "Draft saved.", 
          statusCode: 200}}, show: true });
      }
      /* if (variant !== "SaveDraft") {
        this.$emit("close-dub-modal");
        this.dubEditorData = null;
      } */
      await this.prepareEditorData();
      this.dubLoading = false;
    }
  },
  async mounted() {
    await this.prepareEditorData();
    if (this.user.user.userRole === "STAFF") {
      if (this.dubEditorData.primaryFragments && this.dubEditorData.primaryFragments.staffId === this.user.user.id) {
        this.dubEditorTab = "Primary";
      } else if (this.dubEditorData.secondaryFragments && this.dubEditorData.secondaryFragments.staffId === this.user.user.id) {
        this.dubEditorTab = "Secondary";
      } else if (this.dubEditorData.qcFragments && this.dubEditorData.qcFragments.staffId === this.user.user.id) {
        this.dubEditorTab = "QC";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.anounce-text {
  margin: 0;
  position: absolute;
  top: 70px !important;
  left: 25px;
  font-size: 13px;
  color: rgb(30, 136, 229);
}
.anounce-text-container {
  position: relative;
}
.anounce-text-date {
  left: 45px !important;
  top: 60px !important;
}
.anounce-text-checkbox {
  margin: 0;
  position: absolute;
  top: 35px !important;
  left: 155px;
  font-size: 13px;
  color: rgb(30, 136, 229);
}
.active-button {
  background-color: #1976D2 !important;
  & p {
    color: #fff !important;
  }
}
.custom-button-1 {
  padding: 4px 10px 4px 10px;
  border-radius: 10px;
  border: 2px solid #1976D2CC;
  height: 40px;
}
.custom-button-1-bradius {
  border-radius: 10px 0 0 10px !important;
  margin-right: 2px;
}
.primary-text-1 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.0089em;
  color: #4791DB;
  margin-bottom: 0;
  text-align: left;
  margin-top: -4px;
}
.secondary-text-1 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.0089em;
  color: #4791DB;
  margin-bottom: 0;
  text-align: left;
  margin-top: -3px;
}
.custom-button-2 {
  padding: 4px 10px 4px 10px;
  border-radius: 0 10px 10px 0;
  border: 2px solid #1976D2CC;
  height: 40px;
}
.custom-button-2-bradius {
  border-radius: 0 !important;
  margin-right: 2px;
}
.primary-text-2 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.0089em;
  color: #4791DB;
  margin-bottom: 0;
  text-align: left;
  margin-top: -4px;
}
.secondary-text-2 {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.0089em;
  color: #4791DB;
  margin-bottom: 0;
  margin-top: -3px;
  text-align: left;
}
.custom-button-3 {
  padding: 4px 10px 4px 10px;
  border-radius: 0 10px 10px 0;
  border: 2px solid #1976D2CC;
  height: 40px;
}
.custom-button-4 {
  height: 40px;
  padding: 0px 19.55px 0px 19.55px;
  border-radius: 6px;
  border: 1px solid #757575;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  letter-spacing: 0.0089em;
  text-align: left;
  color: #000000A3;
  text-transform: uppercase;
  margin-left: 10px;
}
.dubEditor {
  &__link {
    color: #1976D2;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: underline;
  }
}
.dub-icon {
  color: #1976D2;
  font-weight: 400;
}
.dub-icon-active {
  color: #fff;
}
.justify-between {
  justify-content: space-between;
}
.confirmation-modal {
  background-color: #fff !important;
  width: 500px !important;
  display: flex;
  flex-direction: column;
  padding: 30px;
  &--title {
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
  }
  &--text {
    color: #000000 !important;
    font-weight: 300 !important;
    font-size: 14px !important;
  }
  &--buttons {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    &-discard {
      color: #fff !important;
    }
  }
}
</style>
