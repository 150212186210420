// imports
import axiosInstance from "./axiosInstance";

// constants
export const leadPMEmail = "anitaxu@companycue.com"; // TODO: replace this const with Staff operational role request

///////////////////////
// GENERAL
///////////////////////

export const doLogin = async ({ email, password }) => {
  try {
    const login = await axiosInstance.post("/auth/login", {
      email,
      password,
    });
    return login.data;
  } catch (err) {
    return err.response;
  }
};

export const changePassword = async ({ id, password }) => {
  try {
    const response = await axiosInstance.put(`/user/password-change/${id}`, {
      password,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const resetPassword = async (id) => {
  try {
    const response = await axiosInstance.put(`/user/password-reset/${id}`, {});
    return response;
  } catch (err) {
    return err.response;
  }
};

export const toTitleCase = (key) => {
  const titleCase = key.replace(/([A-Z])/g, " $1");
  return titleCase.charAt(0).toUpperCase() + titleCase.slice(1);
};

export const getEnum = async (name) => {
  try {
    const response = await axiosInstance.get("/enum", {
      params: name
        ? {
            name,
          }
        : {},
    });
    return name
      ? response.data.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
        )
      : response.data;
  } catch (err) {
    return err.response;
  }
};

export const getFile = async ({ url, table, file, entityId }) => {
  try {
    const response = await axiosInstance.get(
      url ? url.substring(4) : `/${table}/${file}/${entityId}`
    );
    return response.data;
  } catch (err) {
    return err.response;
  }
};

///////////////////////
// STAFF
///////////////////////

export const getAllStaff = async ({ namesOnly, id } = {}) => {
  try {
    const response = await axiosInstance.get(`/staff${id ? `/${id}` : ""}`);
    return id
      ? response.data
      : namesOnly
      ? response.data
          .map((el) => ({
            firstName: el.user.firstName,
            lastName: el.user.lastName,
          }))
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
      : response.data.sort((a, b) =>
          a.user.firstName.localeCompare(b.user.firstName)
        );
  } catch (err) {
    return err.response;
  }
};

export const getLimitedStaffData = async (data) => {
  try {
    const response = await axiosInstance.get("/staff/short-response");
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const processUser = async ({ data, id, defaultCapacity }) => {
  try {
    const reponse = id
      ? defaultCapacity
        ? await axiosInstance.patch(`/staff/${id}/staff-capacity`, data)
        : await axiosInstance.put(`/staff/${id}`, data)
      : await axiosInstance.post("/staff", data);
    return reponse;
  } catch (err) {
    return err.response;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axiosInstance.delete(`/staff/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// CUE PROJECT TOOLS
//////////////////////

export const getProjectTools = async ({ tool, user }) => {
  try {
    const response = await axiosInstance.get(`/cue-${tool}`, {
      params: user
        ? {
            "first-name": user.firstName,
            "last-name": user.lastName,
          }
        : {},
    });
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export const processProjectTool = async ({ tool, data, id }) => {
  try {
    const response = id
      ? await axiosInstance.put(`/cue-${tool}/${id}`, data)
      : await axiosInstance.post(`/cue-${tool}`, data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteProjectTool = async ({ tool, id }) => {
  try {
    const response = await axiosInstance.delete(`/cue-${tool}/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// CALENDAR
//////////////////////

export const getEvents = async ({ user } = {}) => {
  try {
    const response = await axiosInstance.get("/calendar", {
      params: user?.id
        ? {
            "first-name": user.firstName,
            "last-name": user.lastName,
          }
        : {},
    });
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export const processEvent = async ({ data, staffId }) => {
  try {
    const response = data?.id
      ? await axiosInstance.put(`/calendar/${data.id}`, data)
      : await axiosInstance.post("/calendar", data, { params: { staffId } });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteEvent = async (id) => {
  try {
    const response = await axiosInstance.delete(`/calendar/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// CLIENTS
//////////////////////

export const getClients = async () => {
  try {
    const response = await axiosInstance.get("/client/all");
    return response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (err) {
    return err.response;
  }
};

export const processClient = async ({ data, id }) => {
  try {
    const response = id
      ? await axiosInstance.put(`/client/${id}`, data)
      : await axiosInstance.post("/client", data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteClient = async (id) => {
  try {
    const response = await axiosInstance.delete(`/client/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// CLIENT REQUESTERS
//////////////////////

export const getClientRequesters = async () => {
  try {
    const response = await axiosInstance.get("/client-requester");
    return response.data.sort((a, b) =>
      a.user?.firstName?.localeCompare(b?.user?.firstName)
    );
  } catch (err) {
    return err.response;
  }
};

export const processClientRequester = async ({ data, id }) => {
  try {
    const response = id
      ? await axiosInstance.put(`/client-requester/${id}`, data)
      : await axiosInstance.post("/client-requester/create", data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const resetClientRequesterPassword = async ({ id, password }) => {
  try {
    const response = await axiosInstance.put(
      `/client-requester/password-change/${id}`,
      { password }
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteClientRequester = async (id) => {
  try {
    const response = await axiosInstance.delete(`/client-requester/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// PURCHASE ORDERS
//////////////////////

export const getPurchaseOrders = async () => {
  try {
    const response = await axiosInstance.get("/purchase-order");
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export const processPurchaseOrder = async ({ data, id }) => {
  try {
    const response = id
      ? await axiosInstance.put(`/purchase-order/${id}`, data)
      : await axiosInstance.post("/purchase-order", data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deletePurchaseOrder = async (id) => {
  try {
    const response = await axiosInstance.delete(`/purchase-order/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// CUSTOMER PRICE LIST
//////////////////////

export const getCustomerPriceList = async () => {
  try {
    const response = await axiosInstance.get("/customer-price-list");
    return response.data.sort((a, b) =>
      a.workTypeName.localeCompare(b.workTypeName)
    );
  } catch (err) {
    return err.response;
  }
};

export const processCustomerPriceList = async ({ data, id }) => {
  try {
    const response = id
      ? await axiosInstance.put(`/customer-price-list/${id}`, data)
      : await axiosInstance.post("/customer-price-list", data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteCustomerPriceList = async (id) => {
  try {
    const response = await axiosInstance.delete(`/customer-price-list/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// PROJECTS
//////////////////////

export const getAllProjects = async (instance) => {
  try {
    const response = await axiosInstance.get("/project");
    return instance
      ? response.data.filter((el) => el.name === "iTunes FC")
      : response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (err) {
    return err.response;
  }
};

export const getOneProject = async (id) => {
  try {
    const response = await axiosInstance.get(`/project/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getProjectsForClientForm = async (id) => {
  try {
    const response = await axiosInstance.get(`/project/client/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const processProject = async ({ data, id }) => {
  try {
    const response = id
      ? await axiosInstance.put(`/project/${id}`, data)
      : await axiosInstance.post("/project", data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteProject = async (id) => {
  try {
    const response = await axiosInstance.delete(`/project/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// ASSIGNMENTS
//////////////////////

export const getAssignments = async ({
  query,
  clientRequester,
  clientId,
  staffId,
  ledId,
} = {}) => {
  let requestQuery = "";
  if (query) {
    const prevSearch = query.search;
    if (query.search && query.search.includes("|")) query.search = prevSearch.replaceAll("|", "%7C");
    requestQuery = `?${Object.keys(query)
      .map((el) => {
        if (query[el] !== undefined && query[el] !== null)
          return `${el}=${query[el]}`;
      })
      .filter((el) => el !== undefined && el !== null)
      .join("&")}`;
  }
  const special = () => {
    if (clientRequester)
      return `/findByClientRequester?firstName=${clientRequester.user.email}&lastName=${clientRequester.user.email}&email=${clientRequester.user.email}}`;
    else if (clientId) return `/page-by-client/${clientId}`;
    else if (staffId) return `/staff-id/${staffId}`;
    else if (ledId) return `/lead-editor/${ledId}`;
    else if (ledId === null) return "/lead-editor";
    else return "";
  };
  try {
    const response = await axiosInstance.get(
      `/assignment${special()}${requestQuery}`
    );
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export const getAssignmentById = async (id) => {
  try {
    const response = await axiosInstance.get(`/assignment/${id}`);
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export const updateAssignmentsList = async ({payload, requestQuery}) => {
  try {
    const response = await axiosInstance.patch(`/assignment/batch-update${requestQuery}`, payload)
    return response;
  } catch (err) {
    return err.response;
  }
};

export const processAssignment = async ({ data, id, patch, batchEdit }) => {
  try {
    const response = id
      ? patch || batchEdit
        ? await axiosInstance.patch(
            `/assignment/${batchEdit ? "part-update/" : ""}${id}`,
            data
          )
        : await axiosInstance.put(`/assignment/${id}`, data)
      : await axiosInstance.post("/assignment", data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const processAssignmentsList = async (payload) => {
  try {
    const response = await axiosInstance.post(`/assignment/spreadsheet-upload`, payload)
    return response;
  } catch (err) {
    return err.response;
  }
};

export const processClientRequestForm = async (payload) => {
  try {
    const response = await axiosInstance.post(`/assignment/client-request-form`, payload)
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteAssignment = async (id) => {
  try {
    const response = await axiosInstance.delete(`/assignment/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getNewAssignmentFile = async (id) => {
  try {
    const response = await axiosInstance.get(`/assignment-document/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// TYPEFORMS
//////////////////////

export const queryTypeformData = async ({
  typeform_id,
  response_id,
  file_url,
}) => {
  try {
    const response = await axiosInstance.get(
      `/typeform/${
        file_url
          ? `file?fileUrl=${file_url}`
          : `data?typeFormId=${typeform_id}&responseId=${response_id}`
      }`
    );
    return response.data;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// TASKS
//////////////////////

export const getTasks = async ({ query } = {}) => {
  let requestQuery;
  if (query)
    requestQuery = `?${Object.keys(query)
      .map((el) => {
        if (query[el] !== undefined && query[el] !== null)
          return `${el}=${query[el]}`;
      })
      .filter((el) => el !== undefined && el !== null)
      .join("&")}`;
  try {
    const response = await axiosInstance.get(`/task${requestQuery}`);
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export const getNewTasks = async (query, data) => {
  try {
    const defaultParams = {
      page: 0,
      size: 25,
    };
    const params = { ...defaultParams, ...query };
    const queryString = new URLSearchParams(params).toString();
    const response = await axiosInstance.get(`/task/pm-dashboard?${queryString}`, data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const processTask = async ({ data, id, patch }) => {
  try {
    const response = id
      ? patch
        ? await axiosInstance.patch(`/task/${id}`, data)
        : await axiosInstance.put(`/task/${id}`, data)
      : await axiosInstance.post("/task", data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const newGetTaskById = async (id) => {
  try {
    const response = await axiosInstance.get(`/task/${id}`)
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getTaskComments = async (id, query) => {
  try {
    const defaultParams = {
      page: 0,
      size: 20,
    };
    const params = { ...defaultParams, ...query };
    const queryString = new URLSearchParams(params).toString();
    const response = await axiosInstance.get(`/task-activity/task/${id}?sort=commentDateTime,desc&${queryString}`)
    return response;
  } catch (err) {
    return err.response;
  }
};

export const processTaskComment = async (data) => {
  try {
    const response = await axiosInstance.post(`/task-activity`, data)
    return response;
  } catch (err) {
    return err.response;
  }
};

export const newProcessTask = async (data) => {
  try {
    const response = await axiosInstance.put(`/task/insert-update`, data)
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteTask = async (id) => {
  try {
    const response = await axiosInstance.delete(`/task/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// INVOICE
//////////////////////

export const getInvoiceReport = async (month, report) => {
  try {
    const response = await axiosInstance.get(
      `report/invoice?ppm=${
        month === 0 && report === 'LAST' ?
          month + 12 :
          month}&ppy=${month === 0 && report === 'LAST' ?
            new Date().getFullYear() - 1 :
            new Date().getFullYear()
      }`
    );
    return response.data;
  } catch (err) {
    return err.response;
  }
};

//////////////////////
// UPLOAD DATA
//////////////////////

export const uploadExcelData = async (data) => {
  try {
    const response = await axiosInstance.post("/assignment/data-upload", data);
    return response;
  } catch (err) {
    return err.response;
  } 
};


//////////////////////
// CAPACITY
//////////////////////

export const getStaffCapacity = async (data) => {
  try {
    const response = await axiosInstance.get(`/capacity?idList=${data.idList}&selectedDate=${data.selectedDate}`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

//////////////////////
// DROPBOX
//////////////////////

export const getOauthToken = async (data) => {
  try {
    const response = await axiosInstance.get("/dropbox/oauth");
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const getRefreshToken = async (data) => {
  try {
    const response = await axiosInstance.get("/dropbox/tokens?code=dfT-mDcf4scAAAAAAAAB6LNHdJuhELGGOIFoOa75FG4");
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const getAccessToken = async (data) => {
  try {
    const response = await axiosInstance.get(`/dropbox/refresh?accessToken=${data.accessToken}&expiresAt=${data.expiresAt}&refreshToken=${data.refreshToken}`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

//////////////////////
// ENUMS
//////////////////////

export const getAllEnums = async () => {
  try {
    const response = await axiosInstance.get(`/reference`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const getSpecificEnum = async (type) => {
  try {
    const response = await axiosInstance.get(`/${type}`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const createNewEnum = async ({type, data}) => {
  try {
    const response = await axiosInstance.post(`/${type}`, data);
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const updateEnum = async ({type, id, data}) => {
  try {
    const response = await axiosInstance.put(`/${type}/${id}`, data);
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const removeEnum = async ({type, id}) => {
  try {
    const response = await axiosInstance.delete(`/${type}/${id}`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

//////////////////////
// PROJECTS UPDATE
//////////////////////

export const updateProject = async ({id, data}) => {
  try {
    let workTypesString = '';
    data.forEach((el) => {
      workTypesString += `workType=${el}&`;
    })
    const response = await axiosInstance.patch(`/project/${id}/work-type?${workTypesString}`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const updateProject1 = async ({id, data}) => {
  try {
    let workTypesString = '';
    data.forEach((el) => {
      workTypesString += `geos=${el}&`;
    })
    const response = await axiosInstance.patch(`/project/${id}/geos?${workTypesString}`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const updateProject2 = async ({id, data}) => {
  try {
    let workTypesString = '';
    data.forEach((el) => {
      workTypesString += `requestType=${el}&`;
    })
    const response = await axiosInstance.patch(`/project/${id}/request-type?${workTypesString}`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

export const removeDubFragment = async (fragmentId) => {
  try {
    const response = await axiosInstance.delete(`/fragment/${fragmentId}`);
    return response;
  } catch (err) {
    return err.response;
  } 
};

//////////////////////
// DUBEDITOR
//////////////////////

export const processDubEditor = async (payload) => {
  try {
    const response = await axiosInstance.put(`/dub-editor/assignment`, payload)
    return response;
  } catch (err) {
    return err.response;
  }
};
