var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spreadsheet-upload"},[_c('v-card',{staticClass:"spreadsheet-upload__source-section pa-5 mt-5",class:{ active: _vm.sourceFile }},[_c('h2',[_vm._v("Client Files")]),_c('SourceInput'),_c('v-scale-transition',[(
          _vm.sourceFile &&
          !_vm.xContent &&
          !_vm.shakespeare &&
          !_vm.shakespeareLoc &&
          !_vm.cueCopywriting
        )?_c('SourceDropzone'):_vm._e()],1)],1),_c('v-scale-transition',[(_vm.sourceFile && !_vm.dubEditor)?_c('v-card',{staticClass:"pa-5 pt-2 mt-2"},[_c('SourceTable')],1):_vm._e()],1),_c('v-scale-transition',[(_vm.sourceFile)?_c('v-card',{staticClass:"pa-5 mt-5"},[_c('InfoForm')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }