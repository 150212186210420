export const dubEditorFileHeaders = {
  relatedProject: "Project",
  clientRequester: "Client Requester",
  requestDate: "Request Date",
  requestTime: "Request Time",
  po: "Purchase Order",
  file_location: "File Location",
  requestType: "Request Type",
  work_type_unit: "Work Type Unit",
  work_type: "Work Type",
  source_geo: "Source GEO",
  geo: "GEO",
  assignment_title: "Assignment Title",
  assignment_detail: "Assignment Detail",
  task_id: "Task ID",
  quantity: "Quantity",
  word_count: "Word Count",
  primary: "Primary",
  writingDueDate: "Primary Due Date",
  writingDue: "Primary Due Time",
  secondary: "Secondary",
  editingDueDate: "Secondary Due Date",
  editingDue: "Secondary Due Time",
  payPeriod: "Pay Period",
  status: "Assignment Status",
  deliveryDate: "Delivery Date",
  deliveryDue: "Delivery Due Time",
  assignment_note: "Assignment Note",
  qc: "QC",
  reviewingDueDate: "QC Due Date",
  reviewingDue: "QC Due Time",
  mediaType: "Media Type",
  link: "Link",
};

export const sourceFileHeaders = {
  client_requester: "DRI",
  subject_line: "Name",
  source_geo: "Source Locale",
  geo: "Locale",
  media_type: "Line of Business",
  keyword: "Keyword",
  request_type: "Request Type",
  request_date: "Created Date",
  due_date: "Due Date",
  word_count: "Word Count",
  task_name: "Project",
  task_id: "Task ID",
  assignment_id: "ID",
  link: "Task Link",
  shakespeare_link: "Shakespeare Link",
  note: "Instructions",
  new_request_type: "Work Type",
  new_keyword: "Product Line",
};

export const shakespeareHeaders = {
  geo: "LOCALE",
  task_name: "CONTENT_NAME",
  task_id: "WORKFLOW_ID",
  content_id: "ADAM_ID",
  video_content_id: "CONTENT_ID",
  writer: "ASSIGNEE_NAME",
};

export const shakespeareLocHeaders = {
  source_geo: "Source Locale",
  geo: "Locale",
  geo_divider: "Name Ref.",
  task_id: "Workflow Id",
  content_id: "Proxy Adam Id",
  task_name: "Title",
  word_count: "Story Word Count",
  writer: "Assignee Name",
};

export const cueHeaders = {
  relatedProject: "Project",
  po: "Purchase Order",
  client_requester: "Client Requester",
  request_date: "Request Date",
  requestTime: "Request Time",
  task_id: "Task ID",
  source_geo: "Source GEO",
  geo: "GEO",
  assignmentTitle: "Assignment Title",
  task_name: "Assignment Detail",
  word_count: "Word Count",
  quantity: "Quantity",
  media_type: "Media Type",
  fileLocation: "File Location",
  note: "Assignment Note",
  writer: "Primary",
  writingDueDate: "Primary Due Date",
  writingDue: "Primary Due Time",
  editor: "Secondary",
  editingDueDate: "Secondary Due Date",
  editingDue: "Secondary Due Time",
  reviewer: "QC",
  reviewingDueDate: "QC Due Date",
  reviewingDue: "QC Due Time",
  deliveryDate: "Delivery Date",
  deliveryDue: "Delivery Due Time",
  request_type: "Request Type",
  workType: "Work Type",
  payPeriod: "Pay Period",
  status: "Assignment Status",
};

export const BLANK_FORM_VALUES = {
  payPeriod: null,
  assignmentTitle: null,
  writingDueDate: null,
  writingDue: null,
  editingDueDate: null,
  editingDue: null,
  reviewingDueDate: null,
  reviewingDue: null,
  deliveryDate: null,
  deliveryDue: null,
  urgent: false,
  override: false,
  relatedProject: null,
  po: null,
  emailSubjectLine: null,
  clientRequester: null,
  mediaType: null,
  overrideStaff: false,
  requestTime: null,
  fileLocation: null,
  note: null,
  workType: null,
  individualGeosDeadlines: [],
};
