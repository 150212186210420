<template>
  <div class="textarea-wrapper"
    :class="{ 'is-over-max': isOverMax }">
    <label class="textarea-label" 
      :class="{ 'is-over-max-label': isOverMax }"
      v-if="editor && editor.getText()">
      {{ `${data.fragmentName} (${contentCount}/${data.max} ${data.count})` }}
    </label>
    <bubble-menu
      v-if="editor && !isReadOnly"
      :editor="editor"
      persistent
      @click.stop
      :tippy-options="{ duration: 100 }"
    >
      <div class="bubble-menu">
        <div class="button-group">
          <button @click.prevent="editor.chain().focus().toggleBold().run()"><strong>B</strong></button>
          <button @click.prevent="editor.chain().focus().toggleItalic().run()"><em>I</em></button>
          <button @click.prevent="editor.chain().focus().toggleUnderline().run()"><u>U</u></button>
        </div>
      </div>
    </bubble-menu>
    <editor-content :editor="editor" class="editor-content" />
  </div>
</template>

<script>
  import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2';
  import Underline from '@tiptap/extension-underline';
  import StarterKit from '@tiptap/starter-kit';
  import Placeholder from '@tiptap/extension-placeholder';
  import TextStyle from '@tiptap/extension-text-style';

  export default {
    components: {
      EditorContent,
      BubbleMenu,
    },

    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      isReadOnly: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        editor: null,
      }
    },

    computed: {
      contentCount() {
        if (!this.editor) return 0;
        const plainText = this.editor.getText();
        console.log(plainText, this.data.count)
        if (this.data.count === 'words' || this.data.count === 'WORDS') {
          const words = plainText.trim().split(/\s+/);
          return words.filter(word => word.length > 0).length;
        }

        if (this.data.count === 'characters' || 
          this.data.count === 'CHARACTERS' || 
          this.data.count === 'chars' || 
          this.data.count === 'CHARS') {
          return plainText.length;
        }
        return 0;
      },

      isOverMax() {
        return this.contentCount > this.data.max;
      },
    },

    mounted() {
      this.editor = new Editor({
        content: this.data.text ? this.data.text : '',
        editable: !this.isReadOnly,
        extensions: [
          StarterKit,
          Underline,
          TextStyle,
          Placeholder.configure({
            placeholder: ({ node }) => {
              if (node.type.name === 'paragraph') {
                return this.data.fragmentName ? `${this.data.fragmentName} (${this.data.min}/${this.data.max} ${this.data.count})` : 'Start typing...'
              }
              return 'Start typing...'
            },
          }),
        ],
        onUpdate: () => {
          if (!this.isReadOnly) {
            this.$emit('update:content', { editorText: this.editor.getHTML(), data: this.data });
          }
        },
      })
    },

    beforeDestroy() {
      this.editor.destroy()
    },
  }
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  code {
    background-color: rgba(88, 5, 255, .05);
    border-radius: 0.4rem;
    color: #2E2B29;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: #2E2B29;
    border-radius: 0.5rem;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid rgba(61, 37, 20, .12);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid rgba(61, 37, 20, .08);
    margin: 2rem 0;
  }
}
.textarea-wrapper {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-family: Roboto, sans-serif;
  width: 100%;
  min-height: 56px;
  padding: 18px 12px 18px 12px;
  & .textarea-label {
    position: absolute;
    top: -10px; /* Adjust to position above the textarea */
    left: 12px; /* Match the padding of the wrapper */
    background: #fff;
    padding: 0 4px;
    font-size: 12px;
    color: #666;
  }
  & .is-over-max-label {
    color: #FF000099;
  }
}

.is-over-max {
  border: 1px solid #FF000099;
}

.editor-content {
  overflow-y: auto;
  outline: none;
  border: none;
  resize: none;
  font-size: 16px;
  line-height: 20px;
}

/* Bubble menu */
.bubble-menu {
  background-color: #FFF;
  border: 1px solid #D2D2D2;
  border-radius: 6px;
  box-shadow: 0px 12px 33px 0px rgba(0, 0, 0, .06), 0px 3.618px 9.949px 0px rgba(0, 0, 0, .04);
  display: flex;
  padding: 10px 8px 10px 8px;
  .button-group {
    width: 74px;
    height: 24px;
    gap: 10px;
    & button {
      width: 18px;

      &:hover {
        background-color: rgba(61, 37, 20, .12);
      }

      &:nth-child(1) {
        margin-right: 10px;
      }

      &:nth-child(2) {
        margin-right: 10px;
      }

      &.is-active {
        background-color: #c9b0eb;

        &:hover {
          background-color: #9a76c9;
        }
      }
    }
  }
  .select-fonts {
    .dropdown {
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
      appearance: none;
      background: #fff;
      cursor: pointer;
    }
  }
}
p.is-editor-empty:first-child::before {
  color: rgba(53, 38, 28, 0.3);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>